.submission-card {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: rgba(0,0,0,0.5);
    margin: 2rem;
    border-radius: 1rem;
    border: 2px solid RGB(148, 0, 211);
  }

  .main_submission_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading_main_message{
    color: RGB(148, 0, 211);
  }
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
  }

  .label_text{
    color: white;
    font-size: 2rem;
  }
  
  .submit-button {
    background-color: RGB(148, 0, 211);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .input-field {
      font-size: 14px;
    }
  
    .submit-button {
      font-size: 14px;
      padding: 10px 24px;
    }
  }
  