.icon_pack_wrapper{
display: flex;

flex-wrap: wrap;
align-items: center;
justify-content: center;
width: 80vw;

}
.icon_pack_main_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
   

}
.icon_set1_1{
width: 20vw;
height: 30vh;
padding: 2rem;
margin: 1rem;
display: flex;
flex-direction: column;
align-items: center;
background-color: #1c2237;
justify-content: center;
border-radius: 1rem;
border-left: 5px solid RGB(148, 0, 211);
border-right: 5px solid RGB(148, 0, 211);
}

.icon_top{
    font-size: 4rem;
    color: RGB(148, 0, 211);
}

.icon_title{
    color: whitesmoke;
    font-size: 2rem;
    text-align: center;

}

@media screen and (max-width: 768px) {
    .icon_set1_1{
        width: 70vw;
        height: 30vh;
        padding: 2rem;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #1c2237;
        justify-content: center;
        border-radius: 1rem;
        
        }
        
        .icon_top{
            font-size: 3rem;
            color: RGB(148, 0, 211);
        }
        
        .icon_title{
            color: whitesmoke;
            font-size: 1.5rem;
            text-align: center;
        
        }

}