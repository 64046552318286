.home__hero-section {
  color: #fff;
  padding: 16px 0;
  background-color: #b4c4ff;
  display: flex;
  }
  
  .home__hero-row {
  align-items: end;
  }
  
  .row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
  }
  
  .col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  }
  
  .home__hero-text-wrapper {
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 20px;
  }
  
  .top-line2 {
  color: RGB(148, 0, 211);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 16px;
  }
  
  .heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
  }
  
  .dark {
  color: #1c2237;
  }
  
  .darkBg {
  background-color: RGBA(0, 0, 0,0);
  }
  
  .home__hero-subtitle {
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  }
  
  .home__hero-img-wrapper {
  max-width: 555px;

  }
  
  .home__hero-img {
  max-width: 95%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
  border-radius: 1rem;
  border-left: 5px solid RGB(148, 0, 211);
  border-right: 5px solid RGB(148, 0, 211);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  }
  
  img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
  .container {
  padding-right: 10px;
  padding-left: 10px;
  }
  }
  
  @media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
  padding-bottom: 15px;
  }
  
  .col {
  max-width: 100%;
  flex-basis: 100%;
  }
  

  
  .home__hero-img {
    margin-top: 1rem;
    margin-bottom: 1rem;
  background-position: center;
  }
  }