
.center_screen{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
}

@media(max-width: 767px){

    .center_screen{
      
        flex-direction: column;

    }
}
