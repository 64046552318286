.contact-information-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 600px;

  margin: 2rem;
}

.contact-information {
  display: flex;
  flex-direction: column;
  border: 2px solid RGB(148, 0, 211);
  border-radius: 1rem;
  padding: 1rem;
  background-color: RGBA(0, 0, 0, 1);
  margin: 2rem;
  font-size: 2rem;
}

.contact-info:hover{
    padding-left: 10px;
    color: crimson;
    transition: all 0.2s ease-in-out;

}

.contact-section {
  margin-bottom: 1rem;
  background-color: RGBA(0, 0, 0, 0.5);
  border-radius: 1rem;
  padding: 1rem;
}

.contact-title {
  color: RGB(148, 0, 211);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.contact-info {
  color: whitesmoke;
  margin-bottom: 0.25rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-size: 1.5rem;
  
  
}

.contact-image-wrapper {
  padding: 1rem;
}

@media (max-width: 768px) {
  .contact-information {
    font-size: 0.9rem;
  }
  .contact-information-wrapper {
    flex-direction: column;
  }

  .contact-image-wrapper {
    margin-top: 1rem;
  }

  .contact-info {
    font-size: 0.8rem;
  }
  .contact-information {
    font-size: 1.3rem;
  }
}
