.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(270deg, #000000,  RGB(148, 0, 211));
  padding: 1rem;
}


.brand-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.logo {
  font-size: 40px;
  color: #fff;
  margin-left: 1rem;
}


.brand-name {
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
}

.navigation-menu ul {
  display: flex;
  list-style-type: none;
  
  padding: 0;
}

.navigation-menu li {
  margin: 0 0.5rem;
}

.navigation-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
}

.hamburger {
  display: flex;


}


.navigation-menu a:hover {
  color: #ccc;
  background-color: rgba(187,133,171,1);
  border-radius: 0.5rem;
  padding: 1rem;
  font-weight: 800;
  transition: all 0.4s ease-in-out;
}

/* ... your existing styles ... */



@media (max-width: 768px) {

  .hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    position: fixed;
    top: 20px;
    right: 20px;
  }
  
  .hamburger span {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  
  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg);
    background-color: #ccc;
  }
  
  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg);
    background-color: #ccc;
  }
  .navigation-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }

  .navigation-menu ul {
    flex-direction: column;
  }

  .navigation-menu li {
    margin-bottom: 1rem;
  }

  .hamburger {
    display: flex;
  }
}


@media (min-width: 769px) {
  .hamburger {
    display: none;
  }

  .navigation-menu {
    position: initial;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
