.information-section-card {
    background-image: linear-gradient(270deg, RGBA(0, 0, 0, 0.8), RGBA(0, 0, 0, 0.8), RGBA(148, 0, 211, 0.5));
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 2rem;
    border-radius: 1rem;
  }
  
  .information-section-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40vw;
  }
  
  .card_image_2 {
    max-width: 40vw;
    border-radius: 1rem;
  }
  

  .text_small_info{
    font-size: 1.5rem;
  }
.text_heading_info{
    font-size: 2rem;
}

  @media (max-width: 768px) {
    .information-section-card {
      background-image: linear-gradient(0deg, RGBA(0, 0, 0, 0.8), RGBA(0, 0, 0, 0.8), RGBA(148, 0, 211, 0.5));
      flex-direction: column;
    }

    .text_small_info{
        font-size: 1rem;
      }
    .text_heading_info{
        font-size: 1.5rem;
    }

    .information-section-text {

        max-width: 80vw;
      }
  }
  