.submission-confirmation {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 2rem;
    border-radius: 1rem;
    border: 2px solid RGB(148, 0, 211);
    text-align: center;
  }
  
  .confirmation-heading {
    color: RGB(148, 0, 211);
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .confirmation-text {
    color: white;
    font-size: 1.25rem;
  }
  