.hero-home {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  
    color: white;
  }
  
  .hero-home-content {
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    background-color: RGBA(0,0,0,0.7);
    padding: 1rem;
    border-radius: 1rem;
  }

  .main_logo{
    max-width: 20vw;
  }
  
  .title {
    text-align: center;
  }
  
  .title h1 {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }
  
  .title h2 {
    font-size: 1.5rem;
    font-weight: 300;
  }
  
  .company-profile {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  @media screen and (max-width: 768px) {
    .hero-home-content {
      grid-template-columns: 1fr;
    }

    .main_logo{
        max-width: 50vw;
      }
  
    .title h1 {
      font-size: 3rem;
    }
  
    .title h2 {
      font-size: 1.2rem;
    }
  
    .company-profile {
      font-size: 1rem;
    }
  }
  